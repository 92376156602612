:root {
  --color-primary: #3ea086;
  --color-secondary: #3298a0;
  --color-bg-main: #f8f8f8;
  --color-bg-gray: #cccccc;
  --color-white: #ffffff;
  --color-black: #000000;
  --color-light: #f3f3f3;
  --color-primary--light: #eaf5f5;
  --border-testing: 0px solid yellow;
}

*,
*::after,
*::before {
  box-sizing: border-box;
  border: var(--border-testing);
}

/* Typography */

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  font-size: 4.5rem;
  line-height: 5.6rem;
  font-weight: normal;
  letter-spacing: 0.23rem;
}

h2 {
  font-size: 3.5rem;
  line-height: 4rem;
  font-weight: normal;
  letter-spacing: 0.18rem;
}

h3 {
  font-size: 3rem;
  line-height: 4rem;
  font-weight: normal;
  letter-spacing: 0.15rem;
}

p {
  white-space: pre-wrap;
}

.p-h1 {
  font-size: 1.3rem;
  line-height: 1.6rem;
  letter-spacing: 0.06rem;
  margin: 0;
  margin-top: 0.8rem;
}

.p-h2 {
  font-size: 1.6rem;
  line-height: 2.4rem;
  letter-spacing: 0.12rem;
  margin: 0;
  margin-top: 0.8rem;
}

.p-h3 {
  font-size: 1.6rem;
  line-height: 2.4rem;
  letter-spacing: 0.08rem;
}

@media screen and (min-width: 768px) {
  h1 {
    font-size: 6.4rem;
    line-height: 8rem;
    letter-spacing: 0.32rem;
  }

  .p-h1 {
    font-size: 1.8rem;
    line-height: 2.4rem;
    letter-spacing: 0.1rem;
    margin-top: 2.4rem;
  }

  .p-h2 {
    margin-top: 1.6rem;
  }

  .p-h3 {
    font-size: 1.4rem;
    line-height: 2.4rem;
    letter-spacing: 0.08rem;
  }
}

@media screen and (min-width: 1024px) {
  h1 {
    font-size: 8rem;
    line-height: 9.6rem;
    letter-spacing: 0.4rem;
  }

  .p-h1 {
    font-size: 2.6rem;
    line-height: 4rem;
    letter-spacing: 0.13rem;
  }
  h2 {
    font-size: 4rem;
    line-height: 5.6rem;
    font-weight: normal;
    letter-spacing: 0.2rem;
  }

  h3 {
    font-size: 2.5rem;
    line-height: 2.4rem;
    letter-spacing: 0.13rem;
  }

  .p-h2 {
    font-size: 2.4rem;
    line-height: 3.2rem;
    letter-spacing: 0.12rem;
    margin-top: 2.4rem;
  }

  .p-h3 {
    font-size: 1.8rem;
    line-height: 2.8rem;
    letter-spacing: 0.1rem;
  }
}

/* Main Container */

#main-container {
  background-color: var(--color-bg-main);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  width: 100%;
}

#main-container > section:first-of-type {
  margin-top: 0;
}

#main-container > section {
  margin-top: 5.6rem;
}

@media screen and (min-width: 768px) {
  #main-container > section {
    margin-top: 8rem;
  }
}

@media screen and (min-width: 1024px) {
  #main-container > section {
    margin-top: 8.8rem;
  }
}

/* Icons */

.icon img {
  width: 100%;
  height: 100%;
}

.icon--about {
  width: 14.4rem;
  height: 14.4rem;
}

.icon--skill {
  width: 4rem;
  height: 4rem;
}

.icon--project {
  width: 29.6rem;
  height: 29.6rem;
}

.icon--contact {
  width: 8rem;
  height: 8rem;
}

.icon--translate {
  width: 3.2rem;
  height: 3.2rem;
}

.icon--logo {
  width: 3.2rem;
  height: 3.2rem;
}

@media screen and (min-width: 768px) {
  .icon--about {
    width: 10.4rem;
    height: 10.4rem;
  }

  .icon--skill {
    width: 4rem;
    height: 4rem;
  }

  .icon--project {
    width: 29.6rem;
    height: 29.6rem;
  }

  .icon--contact {
    width: 8rem;
    height: 8rem;
  }

  .icon--translate {
    width: 4.8rem;
    height: 4.8rem;
  }

  .icon--logo {
    width: 4.8rem;
    height: 4.8rem;
  }
}

@media screen and (min-width: 1024px) {
  .icon--about {
    width: 11.6rem;
    height: 11.6rem;
  }

  .icon--skill {
    width: 5.6rem;
    height: 5.6rem;
  }

  .icon--project {
    width: 40rem;
    height: 40rem;
  }

  .icon--contact {
    width: 12rem;
    height: 12rem;
  }

  .icon--translate {
    width: 5.6rem;
    height: 5.6rem;
  }

  .icon--logo {
    width: 5.6rem;
    height: 5.6rem;
  }
}

/* Icons with Container */

.icon-with-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-size: contain;
}

.icon-with-container--about {
  width: 21.2rem;
  height: 21.2rem;
  border-radius: 21.2rem;
  background: var(--color-white);
}

.icon-with-container--skill {
  width: 8.8rem;
  height: 8.8rem;
  border-radius: 1.6rem;
  background: var(--color-white);
}

.icon-with-container--project {
  width: 29.6rem;
  height: 29.6rem;
}

.icon-with-container--contact {
  width: 8rem;
  height: 10.4rem;
}

.icon-with-container--skill p {
  margin: 0;
  margin-top: 0.8rem;
  font-size: 1.5rem;
  line-height: 1.6rem;
}

.icon-with-container--contact p {
  color: var(--color-primary--light);
  margin: 0;
  margin-top: 0.8rem;
  font-size: 1.5rem;
  line-height: 1.6rem;
  letter-spacing: 0.2rem;
}

@media screen and (min-width: 768px) {
  .icon-with-container--about {
    width: 16rem;
    height: 16rem;
    border-radius: 16rem;
  }
}

@media screen and (min-width: 1024px) {
  .icon-with-container--about {
    width: 18.4rem;
    height: 18.4rem;
    border-radius: 18.4rem;
    background: var(--color-white);
  }

  .icon-with-container--skill {
    width: 13.6rem;
    height: 13.6rem;
    border-radius: 3.2rem;
  }

  .icon-with-container--project {
    width: 44rem;
    height: 44rem;
  }

  .icon-with-container--contact {
    width: 12rem;
    height: 16.8rem;
  }

  .icon-with-container--skill p {
    font-size: 1.8rem;
    line-height: 2.4rem;
  }

  .icon-with-container--contact p {
    margin-top: 1.6rem;
    font-size: 2.4rem;
    line-height: 3.2rem;
    letter-spacing: 0.36rem;
  }
}

/* Links */

a {
  text-decoration: none;
}

/* Buttons */
.btn {
  display: block;
  padding: 0.8rem 1.6rem;
  width: 20rem;
  height: 3.2rem;
  border-radius: 0.8rem;
  font-size: 1.6rem;
  line-height: 1.6rem;
  text-align: center;
  color: var(--color-white);
}

.btn--black {
  background: #000000;
}

.btn--primary {
  background: var(--color-primary);
}

.btn--secondary {
  background: var(--color-secondary);
}

/* About Items */

.item-about {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25.6rem;
  height: 34rem;
  text-align: center;
}

.item-about h3 {
  margin: 0;
  margin-top: 0.8rem;
  color: var(--color-primary);
}

.item-about p {
  margin: 0;
  margin-top: 0.8rem;
}

@media screen and (min-width: 768px) {
  .item-about {
    width: 21.6rem;
    height: 28.8rem;
  }
}

@media screen and (min-width: 1024px) {
  .item-about {
    width: 29.6rem;
    height: 32.4rem;
  }

  .item-about h3 {
    margin-top: 1.6rem;
  }

  .item-about p {
    margin-top: 1.6rem;
  }
}

/* Project Items */

.item-project {
  display: flex;
  flex-direction: column;
  width: 29.6rem;
  height: 59.2rem;
  border-radius: 1.6rem;
  background: var(--color-white);
}

.item-project__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 29.6rem;
  height: 29.6rem;
  padding: 1.6rem;
}

.item-project h3 {
  margin: 0;
}

.item-project p {
  margin: 0;
  margin-top: 2.4rem;
}

.item-project .btn {
  margin-top: 2.4rem;
}

@media screen and (min-width: 768px) {
  .item-project {
    flex-direction: row;
    width: 59.2rem;
    height: 29.6rem;
  }

  .item-project--reverse {
    flex-direction: row-reverse;
  }
}

@media screen and (min-width: 1024px) {
  .item-project {
    width: 88rem;
    height: 44rem;
    border-radius: 3.2rem;
  }

  .item-project__content {
    width: 44rem;
    height: 44rem;
  }
}

/* Dropdown */

.dropdown {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--color-white);
  width: 8.8rem;
  padding: 0.8rem 0;
  border-radius: 0.8rem;
}

.dropdown a {
  font-size: 1.6rem;
  line-height: 1.6rem;
  text-align: center;
  color: var(--color-black);
  padding: 0.8rem;
  align-self: stretch;
}

.dropdown a:hover {
  background-color: var(--color-light);
}

@media screen and (min-width: 768px) {
  .dropdown {
    width: 10.4rem;
    padding: 1.2rem 0;
  }
}

@media screen and (min-width: 768px) {
  .dropdown {
    width: 12.8rem;
    height: 14.4rem;
  }

  .dropdown a {
    font-size: 1.9rem;
    line-height: 2.4rem;
  }
}

/* Nav Items */
.nav-items {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.nav-items a {
  text-align: center;
  font-size: 1rem;
  line-height: 1.6rem;
  letter-spacing: 0.05rem;
  color: var(--color-white);
  opacity: 0.5;
  width: 4rem;
  margin: 0 0.6rem;
}

.nav-items a:hover {
  opacity: inherit;
}

@media screen and (min-width: 768px) {
  .nav-items a {
    font-size: 1.8rem;
    line-height: 2.4rem;
    letter-spacing: 0.01rem;
    width: 8rem;
    margin: 0 2.8rem;
  }
}

@media screen and (min-width: 1024px) {
  .nav-items a {
    font-size: 3rem;
    line-height: 3.2rem;
    letter-spacing: 0.15rem;
    width: 12rem;
    margin: 0 3.6rem;
  }
}

/* NavBar */
.nav-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  padding: 2.4rem;
  overflow: visible;
  z-index: 2;
}

.nav-bar__translate {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 3.2rem;
  height: 3.2rem;
}

.nav-bar__translate .dropdown {
  margin-top: 1.6rem;
}

@media screen and (min-width: 768px) {
  .nav-bar {
    justify-content: center;
    padding: 4.8rem;
  }

  .nav-bar__translate {
    width: 4.8rem;
    height: 4.8rem;
  }

  .nav-bar .nav-items {
    margin: 0 8.8rem;
  }
}

@media screen and (min-width: 1024px) {
  .nav-bar {
    padding: 6.4rem;
  }

  .nav-bar .nav-items {
    margin: 0 11.2rem;
  }

  .nav-bar__translate {
    width: 5.6rem;
    height: 5.6rem;
  }

  .nav-bar__translate .dropdown {
    margin-top: 3.2rem;
  }
}

/* Hero Section */
.section-hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  height: 40rem;
  position: relative;
}

.section-hero .section-hero__contents {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  z-index: 1;
}

.section-hero #hero_poyao-wang {
  margin: 0;
  margin-top: 6rem;
  height: 8.4rem;
  width: 100%;
}

.section-hero .p-h1 {
  color: var(--color-white);
}

.section-hero #hero-bg {
  bottom: 0;
  position: absolute;
  width: 270rem;
}

@media screen and (min-width: 768px) {
  .section-hero {
    height: 44.8rem;
  }

  .section-hero #hero_poyao-wang {
    margin: 0;
    margin-top: 1.6rem;
    height: 8.8rem;
  }
}

@media screen and (min-width: 1024px) {
  .section-hero {
    height: 63rem;
  }

  .section-hero #hero_poyao-wang {
    margin-top: 5.6rem;
    height: 13.6rem;
  }
}

/* About Section */

.section-about {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section-about h2 {
  margin: 0;
  color: var(--color-primary);
}

.about-items {
  margin: 0;
  margin-top: 4rem;
}

.about-items .item-about:first-child {
  margin-top: 0;
}

.about-items .item-about {
  margin-top: 4rem;
}

@media screen and (min-width: 768px) {
  .about-items {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .about-items .item-about {
    margin: 0px 1.6rem;
  }
}

@media screen and (min-width: 1024px) {
  .about-items .item-about {
    margin: 0px 3rem;
  }
}

/* Skills Section */
.section-skills {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.section-skills h2 {
  margin: 0;
  color: var(--color-primary);
}

.skill-items {
  margin-top: 4rem;
  display: flex;
  flex-direction: row;
}

.skill-items__col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.skill-items .icon-with-container--skill {
  margin: 1.2rem;
}

.skill-items .icon-with-container--skill:first-child {
  margin-top: 0;
}

.skill-items .icon-with-container--skill:last-child {
  margin-bottom: 0;
}

@media screen and (min-width: 768px) {
  .skill-items {
    margin-top: 5.6rem;
  }

  .skill-items .icon-with-container--skill {
    margin: 2.8rem;
  }
}

/* Projects Section */
.section-projects {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.section-projects h2 {
  margin: 0;
  color: var(--color-secondary);
}

.section-projects h3 {
  color: var(--color-secondary);
}

.project-items {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4rem;
}

.project-items .item-project {
  margin-bottom: 4rem;
}

@media screen and (min-width: 768px) {
  .project-items {
    margin-top: 5.6rem;
  }
}

@media screen and (min-width: 1024px) {
  .project-items .item-project {
    margin-bottom: 4.8rem;
  }
}

/* Contact Section */
.section-contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  height: 48rem;
  position: relative;
  overflow: hidden;
}

.section-contact h2 {
  margin: 0;
  color: var(--color-white);
}

.section-contact .p-h2 {
  color: var(--color-white);
}

.section-contact .section-contact__contents {
  margin-top: 16.4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  z-index: 1;
}

.section-contact #contact-bg {
  position: absolute;
  top: 15px;
}

.contact-items {
  display: flex;
  flex-direction: row;
  margin-top: 5.2rem;
}

.contact-items .icon-with-container--contact {
  margin: 0 1.2rem;
}

@media screen and (min-width: 768px) {
  .section-contact {
    height: 47.6rem;
  }

  .section-contact .p-h2 {
    white-space: nowrap;
  }

  .contact-items {
    margin-top: 5.2rem;
  }

  .contact-items .icon-with-container--contact {
    margin: 0 3.2rem;
  }
}

@media screen and (min-width: 1024px) {
  .section-contact {
    height: 63rem;
  }

  .contact-items {
    margin-top: 8.4rem;
  }

  .contact-items .icon-with-container--contact {
    margin: 0 5.2rem;
  }
}

/* Layouts */

.clickable {
  cursor: pointer;
}

.hide {
  display: none;
}

.mobile-hide {
  display: none;
}

@media screen and (min-width: 768px) {
  .mobile-hide {
    display: inherit;
  }
}
